import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router, RouterLinkWithHref } from '@angular/router';
import { injectQueryClient } from '@tanstack/angular-query-experimental';
import { Tenant } from '@trueleap-plus/dto';
import { AuthService, AuthStore } from '@trueleap/auth';
import { NavLinkItemComponent } from '@trueleap/navbar';
import { ExternalIcon, RaisedButton } from '@trueleap/ui-kit';
import { HOST_URL } from '@trueleap/ui-tokens';
import { MatBadgeModule } from '@angular/material/badge';
import { NotificationStore } from './notification.store';
import { MtxDrawer } from '@ng-matero/extensions/drawer';
import { NotificationSidesheetComponent } from './notification.sidesheet';
import { Overlay } from '@angular/cdk/overlay';
import { lastValueFrom } from 'rxjs';
@Component({
  selector: 'landing-nav',
  standalone: true,
  imports: [
    MatButtonModule,
    RouterLinkWithHref,
    NavLinkItemComponent,
    MatIconModule,
    MatMenuModule,
    ExternalIcon,
    RaisedButton,
    CommonModule,
    MatBadgeModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="landing-page-nav-container">
      <div class="landing-page-nav">
        <div class="landing-page-nav_left">
          <a [href]="mainURL" class="landing-page-nav_logo">
            <img src="assets/logos/logo-white.svg" alt="TrueLeap Logo" />
          </a>
        </div>
        <div class="landing-page-nav_links">
          @for (navLink of navLinks(); track navLink.name) {
            <trlp-nav-link-item [navlinkItem]="navLink"></trlp-nav-link-item>
          }
        </div>
        <div class="landing-page-nav_right">
          <button mat-icon-button class="nav-item-button">
            <mat-icon>search</mat-icon>
          </button>

          @if (user(); as user) {
            <span class="profile-user">
              Hello, {{ user.firstname ?? 'User' }}
            </span>
            <div>
              @if (orgs().length) {
                <button
                  mat-icon-button
                  class="nav-item-button"
                  [matMenuTriggerFor]="orgsMenu">
                  <mat-icon>business</mat-icon>
                </button>
                <mat-menu #orgsMenu="matMenu">
                  @if (currentSubdomain()) {
                    <button
                      mat-menu-item
                      *ngIf="currentSubdomain()"
                      class="org-menu-item">
                      <mat-icon>home</mat-icon>
                      {{ currentSubdomain() }}.{{ hostUrl }}
                    </button>
                  }
                  @for (org of orgs(); track org.tenantId) {
                    <button
                      mat-menu-item
                      (click)="selectOrg(org.tenant)"
                      class="org-menu-item">
                      <mat-icon>business</mat-icon>
                      {{ org.tenant.subDomain }}.{{ hostUrl }}
                    </button>
                  }
                </mat-menu>
              }
              <button
                mat-icon-button
                (click)="viewNotifications()"
                class="nav-item-button">
                <mat-icon [matBadge]="unReadCount()" [ariaHidden]="false">
                  notifications
                </mat-icon>
              </button>
              <button
                mat-icon-button
                class="nav-item-button"
                [matMenuTriggerFor]="menu">
                <mat-icon>account_circle</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item [routerLink]="['/my-courses']">
                  <mat-icon external-icon [svgIcon]="'variant160'"></mat-icon>
                  My Courses
                </button>
                <button mat-menu-item [routerLink]="['/my-events']">
                  <mat-icon external-icon [svgIcon]="'variant161'"></mat-icon>
                  My Events
                </button>
                <button mat-menu-item routerLink="/dashboard/profile">
                  <mat-icon>account_circle</mat-icon>
                  Profile
                </button>
                <button mat-menu-item (click)="logout()">
                  <mat-icon>logout</mat-icon>
                  Logout
                </button>
              </mat-menu>
            </div>
          } @else {
            @defer (on immediate) {
              <button
                mat-flat-button
                class="nav-item-button"
                [routerLink]="['/auth']"
                [queryParams]="{ returnUrl: router.url }">
                Login
              </button>
            }
          }
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      @use '@angular/material' as mat;
      @import 'responsive';
      @import 'theme';

      .landing-page-nav-container {
        position: sticky;
        z-index: 4;
        top: 0;
        background: var(--landing-page-background);
      }

      .landing-page-nav {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0.625rem;
        justify-content: space-between;

        @include lg-screen {
          justify-content: flex-start;
        }

        .landing-page-nav_logo {
          img {
            height: 2.5rem;
          }
        }
        .landing-page-nav_links {
          flex-grow: 1;
          display: none;
          align-items: center;
          justify-content: center;
          gap: 1rem;
          @include lg-screen {
            display: flex;
            justify-content: center;
          }
        }

        .landing-page-nav_right {
          margin-left: 1rem;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 10px;

          .mat-body-1 {
            margin: 0;
          }
        }
      }

      .org-menu-item {
        &:hover {
          --mat-menu-item-label-text-color: #{mat.get-theme-color(
              $trueleap-theme,
              primary
            )};
        }
      }
      .profile-user {
        color: white !important;
      }
    `,
  ],
})
export class LandingNav {
  authService = inject(AuthService);
  authStore = inject(AuthStore);
  notificationStore = inject(NotificationStore);
  user = this.authStore.profile;
  orgs = computed(
    () =>
      this.user()?.orgs.filter(
        org => org.tenant.subDomain.toLowerCase() !== this.currentSubdomain()
      ) || []
  );
  queryClient = injectQueryClient();
  currentSubdomain = this.authStore.currentSubDomain;
  currentOrg = this.authStore.currentOrg;
  mainURL = `https://${inject(HOST_URL)}`;
  navLinks = computed(() => {
    let links = [
      {
        name: 'Home',
        url: '/',
        icon: 'home',
      },
      {
        name: 'Courses',
        url: '/courses',
        icon: 'library_books',
      },
      {
        name: 'Events',
        url: '/events',
        icon: 'library_books',
      },
      { name: 'Mentors', url: '/mentors', icon: 'people' },
      {
        name: 'Contact',
        url: '/contact',
        icon: 'mail',
      },
    ];
    if (this.currentSubdomain()) {
      links = links.filter(link => link.name !== 'Home');
    }

    const user = this.user();
    const currentSubdomain = this.currentSubdomain();
    if (user && currentSubdomain) {
      const hasOrgDashboardAccess = this.authService.canLoginToOrg(
        currentSubdomain,
        user
      );
      if (hasOrgDashboardAccess) {
        links.push({
          name: 'My Dashboard',
          url: '/dashboard',
          icon: 'dashboard',
        });
      }
    }

    return links;
  });
  router = inject(Router);
  hostUrl = inject(HOST_URL);

  mtxDrawer = inject(MtxDrawer);
  unread = this.notificationStore.unreadNotifications;
  unReadCount = computed(() => this.unread().length);
  overlayRef = inject(Overlay);

  async logout() {
    await this.authService.logout();
    const subDomain = this.currentSubdomain();
    if (!subDomain) {
      await this.router.navigate(['/auth']);
      return;
    }
    window.location.href = `https://${this.hostUrl}/auth/logout`;
  }

  async selectOrg(org: Tenant) {
    const { token } = await lastValueFrom(this.authService.getCustomToken());
    this.authService.redirectToTenant(org, token);
  }

  viewNotifications() {
    this.mtxDrawer.open(NotificationSidesheetComponent, {
      position: 'right',
      disableClose: true,
      maxWidth: '50vw',
      hasBackdrop: true,
    });
  }
}
