import { computed, inject } from '@angular/core';
import {
  patchState,
  signalStore,
  withComputed,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { NotificationDto, UserNotificationDto } from '@trueleap-plus/dto';
import { AuthStore } from '@trueleap/auth';
import { ulid } from 'ulid';
import { NotificationService } from './notification.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { take, tap } from 'rxjs';
export interface NotificationState {
  notifications: UserNotificationDto[];
}

const initialState: NotificationState = {
  notifications: [],
};

export const NotificationStore = signalStore(
  {
    providedIn: 'root',
    protectedState: false,
  },
  withState(initialState),
  withComputed(({ notifications }) => ({
    unreadNotifications: computed(() => {
      return notifications().filter(notification => !notification.read_at);
    }),
    readNotifications: computed(() => {
      return notifications().filter(notification => !!notification.read_at);
    }),
  })),
  withHooks({
    onInit(store, notificationService = inject(NotificationService)) {
      // notificationService
      //   .getNotifications()
      //   .pipe(
      //     take(1),
      //     takeUntilDestroyed(),
      //     tap(notifications => {
      //       patchState(store, state => {
      //         return {
      //           ...state,
      //           notifications,
      //         };
      //       });
      //     })
      //   )
      //   .subscribe();
    },
  }),
  withMethods((store, authStore = inject(AuthStore)) => ({
    addNotification(notification: NotificationDto) {
      patchState(store, state => {
        return {
          ...state,
          notifications: [
            ...state.notifications,
            {
              notification,
              user: authStore.profile(),
              read_at: null,
              notification_id: notification.id,
              created_at: new Date(),
              id: ulid(),
              createdAt: new Date(),
              updatedAt: new Date(),
              user_id: authStore.profile()?.id || '',
              fcm_ids: authStore.profile()?.fcmTokens || [],
            },
          ],
        };
      });
    },

    markNotificationAsRead(notificationId: string) {
      patchState(store, state => {
        return {
          ...state,
          notifications: state.notifications.map(notification => {
            if (notification.notification_id === notificationId) {
              return {
                ...notification,
                read_at: new Date(),
              };
            }
            return notification;
          }),
        };
      });
    },
  }))
);
